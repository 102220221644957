import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"padding": "0px 200px 0px 200px",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"font": "--headline1",
			"color": "--dark",
			"text-align": "center",
			"sm-font": "normal 700 62px/1.2 \"Source Sans Pro\", sans-serif",
			"children": <>
				Csatlakozzon hozzánk{"  "}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--lead",
			"color": "--dark",
			"text-align": "center",
			"children": "Tapasztalja meg a férfi ápolás legmagasabb szintjét. Vegye fel velünk a kapcsolatot, ha időpontot szeretne foglalni, vagy többet szeretne megtudni szolgáltatásainkról."
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "mailto:blank?contact@unitedmastenga.com",
			"text-align": "center",
			"color": "--dark",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "Email: contact@unitedmastenga.com"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "tel:+36 30 162 7138",
			"text-align": "center",
			"color": "--dark",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "Telefon: +36 30 162 7138"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--headline3",
			"color": "--dark",
			"text-align": "center",
			"children": "Helyszín: Esztergom, Kossuth Lajos u. 57, 2500 Magyarország"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Text {...override("text2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;